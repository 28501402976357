import { Caption, Image } from '@loveholidays/design-system';
import type { ResponsiveImageSize } from '@loveholidays/design-system';
import React from 'react';

import { ContentImageProps, ContentImageSize } from '@AuroraTypes';

const DEFAULT_WIDTH: [number, number, number] = [327, 688, 620];

export const ContentImage: React.FC<ContentImageProps> = ({
  image: { url, description, sourceDimensions },
  displayDescription,
  imageSize,
}) => {
  const heightMap: Record<ContentImageSize, ResponsiveImageSize> = {
    wide: [280, 280, 400],
    default: [180, 280, 280],
    noCrop: sourceDimensions?.aspectRatio
      ? (DEFAULT_WIDTH.map((w) => w / sourceDimensions.aspectRatio) as ResponsiveImageSize)
      : [180, 280, 280],
  };

  // Used to set padding-bottom for noCrop images, based on aspect ratio.
  // Calculation used can be found here: https://css-tricks.com/aspect-ratio-boxes/
  const fluidHeight: `${number}%` | undefined =
    imageSize === 'noCrop' && sourceDimensions
      ? `${(1 / sourceDimensions.aspectRatio) * 100}%`
      : undefined;

  return (
    <div data-id={`ContentImage-${imageSize}`}>
      <Image
        src={url}
        alt={description}
        width={DEFAULT_WIDTH}
        height={heightMap[imageSize]}
        fluidHeight={fluidHeight}
      />
      {displayDescription && (
        <Caption
          variant="small"
          sx={{
            display: 'block',
            marginTop: '2xs',
          }}
        >
          {description}
        </Caption>
      )}
    </div>
  );
};
